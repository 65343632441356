import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';

export default function ShowcasePortfolioHome() {
  pageTitle('Projects');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heroSocialLinks = [
    {
      name: 'LinkedIn',
      links: 'https://linkedin.com/company/lagos-labs',
    },
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/lagoslabs',
    },
    {
      name: 'Twitter',
      links: 'https://www.twitter.com/lagoslabs',
    },
  ];

  const showcaseData = [
    {
      title: 'Mehchant  <br/>e-commerce',
      imgUrl: '/images/mehchant.jpg',
      href: '/projects/Mehchant',
    },
    {
      title: 'Smart <br/> Merchandising',
      imgUrl: '/images/loverrr_2.png',
      href: '/projects/merchandising',
    },
    {
      title: 'Digital <br/> Fan Token',
      imgUrl: '/images/creative_1.jpg',
      href: '/projects/faneconomy',
    },
    {
      title: 'Fan <br/> Activation',
      imgUrl: '/images/creative_5.png',
      href: '/projects/activation',
    },
  ];

  return (
    <Hero6
      heroSocialLinks={heroSocialLinks}
      socialLinksHeading="Follow Us"
      showcaseData={showcaseData}
    />
  );
}
